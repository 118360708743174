<template>
  <div>
    <Filters
      :copy="copy"
      :rows="rows"
      @changed="handleChangeRows"
      :datum="datum"
      :effectivzins="effectivzins"
      :sollzins="sollzins"
      :kaufpreis="kaufpreis"
      :darlehen="darlehen"
    />
    <v-data-table
      :headers="headers"
      :items="copy"
      :items-per-page="copy.length"
      class="elevation-1 kfw-first-table"
      item-key="name"
      fixed-header
      hide-default-footer
    >
      <template v-slot:[`item.effektivzinsMittelwert`]="{ item }">
        <span>{{ `${separator(item.effektivzinsMittelwert)} %` }}</span>
      </template>
      <template v-slot:[`item.sollzinsMittelwert`]="{ item }">
        <span>{{ `${separator(item.sollzinsMittelwert)} %` }}</span>
      </template>
      <template v-slot:[`item.sollzins`]="{ item }">
        <span>{{ `${separator(item.sollzins)} %` }}</span>
      </template>
      <template v-slot:[`item.effektivzins`]="{ item }">
        <span>{{ `${separator(item.effektivzins)} %` }}</span>
      </template>
      <template v-slot:[`item.datum`]="{ item }">
        <span>{{ $date(item.datum).format('DD.MM.YYYY') }}</span>
      </template>
      <template v-slot:[`item.sollzinsbindung`]="{ item }">
        <span>{{ `${item.sollzinsbindung} Jahre` }}</span>
      </template>
      <template v-slot:[`item.darlehen`]="{ item }">
        <span>{{ `${item.darlehen.toLocaleString('de-DE')} €` }}</span>
      </template>
      <template v-slot:[`item.kaufpreis`]="{ item }">
        <span>{{ `${item.kaufpreis.toLocaleString('de-DE')} €` }}</span>
      </template>
      <template v-slot:[`item.berechnungId`]="{ item }">
        <span class="top-anbieter" @click="fetchTop(item)"
          >TOP-30-Anbieter</span
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

import Filters from '../../Spezialabonnenten/Filters/Filters';

import { modifyNumber } from '../../../../../src/utils/helpers';

export default {
  name: 'KfwFirstTable',
  props: {
    headers: Array,
    rows: Array,
    darlehen: {
      type: Boolean,
      default: false,
    },
    kaufpreis: {
      type: Boolean,
      default: false,
    },
    effectivzins: {
      type: Boolean,
      default: false,
    },
    datum: {
      type: Boolean,
      default: false,
    },
    sollzins: {
      type: Boolean,
      default: false,
    },
  },
  components: { Filters },
  data() {
    return {
      copy: [],
    };
  },
  watch: {
    rows(val) {
      this.copy = [...val];
    },
  },
  methods: {
    ...mapActions({
      fetchDataForSecondKfwTable:
        'specialSubscribers/fetchDataForSecondKfwTable',
    }),
    ...mapMutations({
      resetKfwSecondTable: 'specialSubscribers/resetKfwSecondTable',
    }),
    async fetchTop(item) {
      await this.resetKfwSecondTable('baugeld');
      await this.fetchDataForSecondKfwTable({
        id: item.berechnungId,
        productType: 'baugeld',
      });
      this.$emit('modal', item);
    },
    separator(val) {
      return modifyNumber(val);
    },
    handleChangeRows(val) {
      this.copy = val;
    },
  },
  mounted() {
    this.copy = [...this.rows];
  },
};
</script>
<style lang="scss" scoped>
.kfw-first-table > .v-data-table__wrapper > table > tbody > tr > td {
  & > .top-anbieter {
    color: $link-color !important;
    text-decoration: underline !important;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
