<template>
  <div>
    <KfwBaugeldIndex />
  </div>
</template>

<script>
import KfwBaugeldIndex from '../../../wettbewerbsvergleich/src/components/Kfw/KfwBaugeld/KfwBaugeldIndex';
export default {
  name: 'KfwBaugeld',
  components: { KfwBaugeldIndex },
};
</script>

<style lang="css" scoped></style>
