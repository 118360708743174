<template>
  <v-col class="picker">
    <div class="data-picker">
      <v-menu
        :color="color"
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        offset-x
        left
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <div class="d-flex">
            <v-icon
              v-on="on"
              class="mdiCalendar"
              :class="{
                'active-calendar': menu,
              }"
              >{{ 'mdi-calendar' }}
            </v-icon>
          </div>
        </template>
        <v-date-picker
          locale="de-DE"
          :color="color"
          v-model="dates"
          min="2010-12-01"
          :max="new Date().toISOString().substring(0, 10)"
          :allowed-dates="getAllowedDates"
          @change="saveDate"
          range
        ></v-date-picker>
      </v-menu>
      <p class="date">
        {{ dateRangeText }}
      </p>
    </div>
  </v-col>
</template>

<script>
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'KfwDatePicker',
  props: {
    storeNamespace: {
      type: String,
      default: 'specialSubscribers',
    },
    storePath: String,
  },
  computed: {
    dateRangeText() {
      return this.dates
        .map((item) => this.$date(item).format('DD.MM.YY'))
        .join(' ~ ');
    },
    getDates() {
      return this.$store.getters[this.storeNamespace + '/getFieldValue'](
        this.storePath
      );
    },
  },
  watch: {
    getDates(val) {
      this.dates = val;
    },
  },
  data() {
    return {
      color: BASE_BLACK_COLOR,
      dates: [],
      menu: false,
    };
  },
  mounted() {
    this.dates = this.getDates;
  },
  methods: {
    getAllowedDates(value) {
      const [start, end] = this.dates;
      if (end) return true;
      return (
        this.$date(value).isBefore(this.$date(start).add(1, 'M')) &&
        this.$date(value).isAfter(this.$date(start).subtract(1, 'M'))
      );
    },
    saveDate(date) {
      const dates = date.map((item) => {
        return new Date(item).getTime();
      });
      const min = this.$date(new Date(Math.min(...dates))).format('YYYY-MM-DD');
      const max = this.$date(new Date(Math.max(...dates))).format('YYYY-MM-DD');
      const result = [min, max];
      this.$refs.menu.save(date);
      this.$store.commit(this.storeNamespace + '/setFieldValue', {
        path: this.storePath,
        value: result,
      });
      this.$emit('change');
    },
  },
};
</script>
<style lang="scss" scoped>
.active-calendar {
  color: $text;
}
.date {
  display: flex;
}
.picker {
  position: relative;
  padding-top: 10px !important;
  padding-left: 0 !important;
}
</style>
