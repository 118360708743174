var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"second-table-container"},[_c('v-data-table',{staticClass:"elevation-1 kfw-second-table",attrs:{"headers":_vm.headers,"items":_vm.rows,"items-per-page":_vm.rows.length,"item-key":"name","fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.sollzins",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.sollzins)) + " %")))])]}},{key:"item.effektivzins",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.effektivzins)) + " %")))])]}},{key:"item.datum",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$date(item.datum).format('DD.MM.YYYY')))])]}},{key:"item.sollzinsbindung",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.sollzinsbindung) + " Jahre")))])]}},{key:"item.darlehen",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.darlehen.toLocaleString('de-DE')) + " €")))])]}},{key:"item.kaufpreis",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.kaufpreis.toLocaleString('de-DE')) + " €")))])]}},{key:"item.regionaleBank",fn:function(ref){
var item = ref.item;
return [(item.regionaleBank === true)?_c('span',[_vm._v("Ja")]):_vm._e()]}},{key:"item.fmhIndexBank",fn:function(ref){
var item = ref.item;
return [(item.fmhIndexBank === true)?_c('span',[_vm._v("Ja")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }