var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Filters',{attrs:{"copy":_vm.copy,"rows":_vm.rows,"datum":_vm.datum,"effectivzins":_vm.effectivzins,"sollzins":_vm.sollzins,"kaufpreis":_vm.kaufpreis,"darlehen":_vm.darlehen},on:{"changed":_vm.handleChangeRows}}),_c('v-data-table',{staticClass:"elevation-1 kfw-first-table",attrs:{"headers":_vm.headers,"items":_vm.copy,"items-per-page":_vm.copy.length,"item-key":"name","fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.effektivzinsMittelwert",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.effektivzinsMittelwert)) + " %")))])]}},{key:"item.sollzinsMittelwert",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.sollzinsMittelwert)) + " %")))])]}},{key:"item.sollzins",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.sollzins)) + " %")))])]}},{key:"item.effektivzins",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.separator(item.effektivzins)) + " %")))])]}},{key:"item.datum",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$date(item.datum).format('DD.MM.YYYY')))])]}},{key:"item.sollzinsbindung",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.sollzinsbindung) + " Jahre")))])]}},{key:"item.darlehen",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.darlehen.toLocaleString('de-DE')) + " €")))])]}},{key:"item.kaufpreis",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.kaufpreis.toLocaleString('de-DE')) + " €")))])]}},{key:"item.berechnungId",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"top-anbieter",on:{"click":function($event){return _vm.fetchTop(item)}}},[_vm._v("TOP-30-Anbieter")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }