<template>
  <div class="second-table-container">
    <v-data-table
      :headers="headers"
      :items="rows"
      :items-per-page="rows.length"
      class="elevation-1 kfw-top-worst"
      item-key="name"
      fixed-header
      hide-default-footer
    >
      <template v-slot:[`item.sollzins`]="{ item }">
        <span>{{ `${separator(item.sollzins)} %` }}</span>
      </template>
      <template v-slot:[`item.effektivzins`]="{ item }">
        <span>{{ `${separator(item.effektivzins)} %` }}</span>
      </template>
      <template v-slot:[`item.datum`]="{ item }">
        <span>{{ $date(item.datum).format('DD.MM.YYYY') }}</span>
      </template>
      <template v-slot:[`item.sollzinsbindung`]="{ item }">
        <span>{{ `${item.sollzinsbindung} Jahre` }}</span>
      </template>
      <template v-slot:[`item.darlehen`]="{ item }">
        <span>{{ `${item.darlehen.toLocaleString('de-DE')} €` }}</span>
      </template>
      <template v-slot:[`item.kaufpreis`]="{ item }">
        <span>{{ `${item.kaufpreis.toLocaleString('de-DE')} €` }}</span>
      </template>
      <template v-slot:[`item.regionaleBank`]="{ item }">
        <span v-if="item.regionaleBank === true">Ja</span>
      </template>
      <template v-slot:[`item.fmhIndexBank`]="{ item }">
        <span v-if="item.fmhIndexBank === true">Ja</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { modifyNumber } from '../../../../../src/utils/helpers';

export default {
  name: 'TopWorst',
  props: {
    headers: Array,
    rows: Array,
  },
  components: {},
  methods: {
    separator(val) {
      return modifyNumber(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.second-table-container {
  padding: 0 24px 16px 24px;
}

.headline {
  color: $secondary-a;
  font-weight: bolder;
}
</style>
