<template>
  <div>
    <Loader v-if="loading" />
    <div class="form" :style="defaultStyle">
      <kfw-baugeld-form />
    </div>
    <div class="box__error">
      <v-alert
        v-if="error.baugeld"
        border="right"
        colored-border
        type="error"
        elevation="1"
        >Nicht gefunden</v-alert
      >
    </div>
    <v-container>
      <kfw-first-table
        @modal="handleChangeData"
        sollzins
        datum
        darlehen
        kaufpreis
        effectivzins
        v-if="firstTable.baugeld.rows.length && !error.baugeld"
        :rows="firstTable.baugeld.rows"
        :headers="firstTable.baugeld.headers"
      />
    </v-container>
    <v-dialog v-model="modal">
      <v-card>
        <v-card-text>
          <div class="card-info">
            <v-row>
              <v-col>
                <h2 class="headline">TOP-30-Anbieter</h2>
              </v-col>
              <v-col class="d-flex justify-end"
                ><v-btn :color="color" text @click="toggleModal">
                  Schließen
                </v-btn></v-col
              >
            </v-row>
            <v-row class="flex-column">
              <v-col class="base-normal-color">
                <b>Datum:</b> {{ date }}
              </v-col>
              <v-col class="base-normal-color">
                <b>Darlehen:</b> {{ darlehen }} Euro,
                {{ sollzinsbindung }} Jahre fest
              </v-col>
              <v-col class="base-normal-color">
                <b>Kaufpreis:</b> {{ kaufpreis }} Euro
              </v-col>
              <v-col class="base-normal-color">
                <b>
                  Mittelwert, bezogen auf die besten Effektivzinsen aus der
                  FMH-Indexgruppe:
                </b>
                {{ effektivzinsMittelwert }} %
              </v-col>
              <v-col class="base-normal-color">
                <b>
                  Mittelwert, bezogen auf die besten Sollzinsen aus der
                  FMH-Indexgruppe:
                </b>
                {{ sollzinsMittelwert }} %
              </v-col>
              <v-col class="base-normal-color">
                Im FMH-IndeX werden bei Vermittlern alle passenden Angebote
                berücksichtigt und bei diesem Wert fließt nur das jeweils beste
                Abgebot in die Berechnung mit ein.
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <div class="filter-second-table">
          <v-btn :color="color" @click="filterFmh">FMH-IndeX-Banken</v-btn>
        </div>
        <kfw-second-table
          v-if="secondTableData.rows.length"
          :rows="secondTableData.rows"
          :headers="secondTableData.headers"
        />
        <top-worst
          v-if="topWorst.rows.length"
          :rows="topWorst.rows"
          :headers="topWorst.headers"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import KfwBaugeldForm from './KfwBaugeldForm';
import Loader from '../../common/Loader/Loader';
import KfwFirstTable from '../KfwTables/KfwFirstTable';
import KfwSecondTable from '../KfwTables/KfwSecondTable';
import TopWorst from '../KfwTables/TopWorst';

import { numberWithCommas } from '../../../utils/helpers';

import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'KfwBaugeldIndex',
  components: {
    TopWorst,
    KfwBaugeldForm,
    KfwFirstTable,
    KfwSecondTable,
    Loader,
  },
  computed: {
    ...mapGetters({
      secondTable: 'specialSubscribers/getKfwSecondTable',
      firstTable: 'specialSubscribers/getKfwFirstTable',
      loading: 'specialSubscribers/getLoading',
      error: 'specialSubscribers/getErrorKfw',
    }),
    secondTableData() {
      if (Object.keys(this.filtered).length) {
        return this.filtered;
      } else {
        return this.topThirty;
      }
    },
    defaultStyle() {
      const height = window.innerHeight - 335;
      return {
        'min-height': `${height}px`,
      };
    },
  },
  data() {
    return {
      topWorst: {
        rows: [],
        headers: [],
      },
      topThirty: {
        rows: [],
        headers: [],
      },
      filtered: {},
      color: BASE_BLACK_COLOR,
      modal: false,
      date: '',
      darlehen: '',
      sollzinsbindung: '',
      effektivzinsMittelwert: '',
      sollzinsMittelwert: '',
      kaufpreis: '',
    };
  },
  watch: {
    modal(val) {
      this.filtered = {};
      if (!val) {
        this.topWorst = {
          headers: [],
          rows: [],
        };
        this.topThirty = {
          headers: [],
          rows: [],
        };
      }
    },
    secondTable: {
      deep: true,
      handler(val) {
        if (val.baugeld.rows.length) {
          this.topThirty = {
            ...val.baugeld,
            rows: val.baugeld.rows.slice(10, 40).reverse(),
          };

          this.topWorst = {
            headers: val.baugeld.headers.map((item) => {
              if (item.value === 'anbietername') {
                return {
                  value: 'anbietername',
                  text: '10 teuerste Anbieter',
                };
              }
              return item;
            }),
            rows: val.baugeld.rows.slice(0, 10),
          };
        }
      },
    },
  },
  methods: {
    handleChangeData(data) {
      this.modal = true;
      this.date = this.$date(data.datum).format('DD.MM.YYYY');
      this.darlehen = numberWithCommas(data.darlehen);
      this.sollzinsbindung = numberWithCommas(data.sollzinsbindung);
      this.effektivzinsMittelwert = numberWithCommas(
        String(data.effektivzinsMittelwert).length > 3
          ? data.effektivzinsMittelwert.toFixed(2)
          : data.effektivzinsMittelwert
      );
      this.sollzinsMittelwert = numberWithCommas(
        String(data.sollzinsMittelwert).length > 3
          ? data.sollzinsMittelwert.toFixed(2)
          : data.sollzinsMittelwert
      );
      this.kaufpreis = numberWithCommas(data.kaufpreis);
    },
    toggleModal() {
      this.modal = !this.modal;
    },
    filterFmh() {
      if (Object.keys(this.filtered).length) {
        this.filtered = {};
      } else {
        const rows = this.topThirty.rows.filter((item) => item.fmhIndexBank);
        this.filtered = {
          ...this.topThirty,
          rows,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  color: $link-color;
  font-weight: bolder;
}
.card-info {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
}
.filter-second-table {
  padding: 0 24px 16px 24px;
  button {
    color: $white;
  }
}
</style>
