var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-content"},[_c('v-container',{staticClass:"baugeld-kfw-form"},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"form-headline-content"},[_vm._v("Kfw-Auswertung: Baugeld")])])],1),_c('v-form',[_c('v-row',[_c('v-col',[_c('v-card-text',{staticClass:"pl-0 font"},[_vm._v(" Sollzinsbindung ")]),_c('v-select',{attrs:{"color":_vm.color,"item-color":"selected","attach":true,"background-color":"white","item-text":"text","item-value":"value","solo":"","dense":"","items":[
              { text: 'Alle', value: 0 },
              { text: '5 Jahre', value: 5 },
              { text: '10 Jahre', value: 10 },
              { text: '15 Jahre', value: 15 },
              { text: '20 Jahre', value: 20 } ]},on:{"change":_vm.handleChangeValue},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.text))])]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.sollzinsbindung),callback:function ($$v) {_vm.sollzinsbindung=_vm._n($$v)},expression:"sollzinsbindung"}})],1),_c('v-col',[_c('v-card-text',{staticClass:"pl-0 font"},[_vm._v(" Datum ")]),_c('kfw-date-picker',{attrs:{"storePath":"formKfwBaugeld.dates","storeNamespace":"specialSubscribers"},on:{"change":_vm.handleChangeDate}})],1)],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-spacer'),_c('v-col',{attrs:{"sm":"5","lg":"4"}},[_c('v-btn',{staticClass:"pl-0 action-btn",attrs:{"color":_vm.color,"plain":""},on:{"click":_vm.resetForm}},[_vm._v("zurücksetzen")])],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',[_vm._v(" Änderungen der Vorgaben ab 13.06.2018: 350,000€ Kaufpreis, 315,000/280,000€ Darlehen."),_c('br'),_vm._v(" Verwendete Vorgaben für die Berechnung: 2% Tilgung, fertiges Objekt, Angestellter, keine Sondertilgung"),_c('br'),_vm._v(" Die Tilgungsanhebung von 1% auf 2% erfolgte am 01.09.2015."),_c('br'),_c('br'),_vm._v(" Bei den Vermittlern werden bei den Mittelwerten alle Bankangebote berücksichtigt, die an die FMH gemeldet werden. "),_c('br'),_c('br')])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }