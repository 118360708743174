<template>
  <div class="form-content">
    <v-container class="baugeld-kfw-form">
      <v-row>
        <v-col>
          <h2 class="form-headline-content">Kfw-Auswertung: Baugeld</h2>
        </v-col>
      </v-row>
      <v-form>
        <v-row>
          <v-col>
            <v-card-text class="pl-0 font"> Sollzinsbindung </v-card-text>
            <v-select
              :color="color"
              item-color="selected"
              :attach="true"
              background-color="white"
              v-model.number="sollzinsbindung"
              @change="handleChangeValue"
              item-text="text"
              item-value="value"
              solo
              dense
              :items="[
                { text: 'Alle', value: 0 },
                { text: '5 Jahre', value: 5 },
                { text: '10 Jahre', value: 10 },
                { text: '15 Jahre', value: 15 },
                { text: '20 Jahre', value: 20 },
              ]"
            >
              <template #selection="{ item }">
                <span>{{ item.text }}</span>
              </template>
              <template v-slot:item="{ item }"
                ><span>{{ item.text }}</span></template
              >
            </v-select>
          </v-col>
          <v-col>
            <v-card-text class="pl-0 font"> Datum </v-card-text>
            <kfw-date-picker
              storePath="formKfwBaugeld.dates"
              storeNamespace="specialSubscribers"
              @change="handleChangeDate"
            />
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-spacer></v-spacer>
          <v-col sm="5" lg="4">
            <v-btn
              class="pl-0 action-btn"
              :color="color"
              plain
              @click="resetForm"
              >zurücksetzen</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row>
          <v-col>
            Änderungen der Vorgaben ab 13.06.2018: 350,000€ Kaufpreis,
            315,000/280,000€ Darlehen.<br />
            Verwendete Vorgaben für die Berechnung: 2% Tilgung, fertiges Objekt,
            Angestellter, keine Sondertilgung<br />
            Die Tilgungsanhebung von 1% auf 2% erfolgte am 01.09.2015.<br /><br />
            Bei den Vermittlern werden bei den Mittelwerten alle Bankangebote
            berücksichtigt, die an die FMH gemeldet werden. <br /><br />
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import KfwDatePicker from '../KfwDatePicker/KfwDatePicker';
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'KfwBaugeldForm',
  components: { KfwDatePicker },
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
  computed: {
    ...mapGetters({
      data: 'specialSubscribers/getFormKfwBaugeld',
      isAuthorized: 'auth/isAuthorized',
      dataTable: 'specialSubscribers/getKfwFirstTable',
    }),
    ...mapFields('specialSubscribers', ['formKfwBaugeld.sollzinsbindung']),
    ...mapFields('specialSubscribers', ['formKfwBaugeld.dates']),
  },
  methods: {
    ...mapMutations({
      resetKfwTables: 'specialSubscribers/resetKfwTables',
      resetKfwFormBaugeld: 'specialSubscribers/resetKfwFormBaugeld',
    }),
    ...mapActions({
      fetchDataForFirsKfwTable: 'specialSubscribers/fetchDataForFirsKfwTable',
    }),
    async handleChangeValue() {
      await this.resetKfwTables('baugeld');
      return this.fetchDataForFirsKfwTable(this.data);
    },
    async handleChangeDate() {
      await this.resetKfwTables('baugeld');
      return this.fetchDataForFirsKfwTable(this.data);
    },
    async resetForm() {
      await this.resetKfwFormBaugeld();
      await this.resetKfwTables('baugeld');
      return this.fetchDataForFirsKfwTable(this.data);
    },
  },
  watch: {
    isAuthorized(val) {
      if (val && !Object.values(this.dataTable.baugeld).flat().length) {
        this.fetchDataForFirsKfwTable(this.data);
      }
    },
  },
  created() {
    if (
      this.isAuthorized &&
      !Object.values(this.dataTable.baugeld).flat().length
    ) {
      this.fetchDataForFirsKfwTable(this.data);
    }
  },
};
</script>

<style lang="scss" scoped>
.my-primary {
  color: $white;
}
.baugeld-kfw-form {
  padding-top: 25px;
  & > div > div > h2 {
    padding-bottom: 30px !important;
  }
}
</style>
